import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"

const ConsultingServices = () => {
    return (
        <div>
            <Header  pageTitle="Consulting Services"/>
            <section className="py-5 px-md-5 mx-xl-5">
                <Container>
                    <h2 className="text-theme">Consulting Services</h2>
                    <h5 className="mt-3">Virtual Chief Information Security Officer</h5>
                    <Row>
                        <Col md="12">
                            <p>While many organizations have a mature Information Technology (IT) department,
                            they often have understaffed or underdeveloped cyber security departments.
                            The core function of the cyber security department is to interface with both
                            the compliance/internal audit team and the IT operations teams to ensure security
                            is addressed in all aspects of the corporate infrastructure and culture.
                            ESG provides experienced C-level executives to develop and/or augment the
                            current cyber security department. Specifically, ESG professionals will develop a
                            corporate cyber security Governance Program tailored to fit each organization.
                                The program includes at a minimum:</p>

                            <ul>
                                <li className="my-3">
                                    <i className="fa fa-chevron-right"></i> Governance Policy Library
                                    <ul className="list-style-circle ml-5">
                                        <li>Policies, process, and procedure development</li>
                                        <li>Information security awareness training</li>
                                    </ul>
                                </li>
                                <li className="my-3">
                                    <i className="fa fa-chevron-right"></i> Strategic Planning
                                    <ul className="list-style-circle ml-5">
                                        <li>Develop an Information Security Roadmap</li>
                                        <li>12, 24, and 36-month implementation plan</li>
                                    </ul>
                                </li>
                                <li className="my-3">
                                    <i className="fa fa-chevron-right"></i> Compliance Program development
                                    <ul className="list-style-circle ml-5">
                                        <li>Develop a comprehensive regulatory compliance audit schedule</li>
                                        <li>Execute all annual audits and risk assessments</li>
                                    </ul>
                                </li>
                                <li className="my-3">
                                    <i className="fa fa-chevron-right"></i> Corporate representation
                                    <ul className="list-style-circle ml-5">
                                        <li>Serve as corporate representative on all cyber security matters</li>
                                        <li>Interface with all regulatory bodies and vendors on all security related issues</li>
                                    </ul>
                                </li>
                            </ul>

                            <h5 className="my-3 font-weight-bold">Virtual Chief Information Security Officer</h5>

                            <p className="my-3">An often-overlooked tool by many IT executives and managers is to have an
                            independent third-party review of the baseline configuration files of all network devices.
                                ESG subject matter experts will:</p>

                            <ul>
                                <li><i className="fa fa-chevron-right"></i> Conduct detailed Enterprise Architecture and Infrastructure reviews
                                    <ul className="list-style-circle ml-5">
                                        <li>Provide design recommendations to increase bandwidth, efficiency and maximize security</li>
                                    </ul>
                                </li>
                                <li><i className="fa fa-chevron-right"></i> Conduct In-depth review of system, device, application, and database security configurations</li>
                                <li><i className="fa fa-chevron-right"></i> Reviews are based on industry standard security templates</li>
                                <li><i className="fa fa-chevron-right"></i> Router, switch, firewall, Intrusion detection/prevention, load balancers</li>
                                <li><i className="fa fa-chevron-right"></i> Server: Linux, Windows</li>
                                <li><i className="fa fa-chevron-right"></i> Applications code reviews</li>
                                <li><i className="fa fa-chevron-right"></i> Database: MS SQL, MySQL</li>
                                <li><i className="fa fa-chevron-right"></i> Provide a detailed report with recommended fix actions for system administrators and security engineers.</li>
                            </ul>

                            <h2 className="text-theme my-3">Security Audits/Assessments</h2>

                            <p className="my-3">One of the first steps to reduce digital threats and provide
                            security assurances is to conduct an audit. Through this process, a detailed
                            investigation is carried out on the vulnerabilities of digital platform(s).
                            From this analysis, it is not only possible to access the risks that a
                            company is facing but it is also possible to obtain
                            information about the strengths of its infrastructure.</p>

                            <h5 className="my-3 font-weight-bold">Within the different audits/assessments, we can differentiate:</h5>

                            <ul className="my-3">
                                <li><i className="fa fa-chevron-right"></i> External Penetration Test, for the services present on the Internet.</li>
                                <li><i className="fa fa-chevron-right"></i> Internal Penetration Test, for the local networks.</li>
                                <li><i className="fa fa-chevron-right"></i> Network Audit, to review traffic flow control and existing monitoring.</li>
                                <li><i className="fa fa-chevron-right"></i> Mobile Devices Audit, for the security management of these devices.</li>
                                <li><i className="fa fa-chevron-right"></i> Audit of existing Digital Certificates within the organization.</li>
                                <li><i className="fa fa-chevron-right"></i> Information Leakage Audit, to identify potential security holes that can potentially exfiltrate data.</li>
                                <li><i className="fa fa-chevron-right"></i> Software Licensing Audit, to control the licenses acquired and installed.</li>
                                <li><i className="fa fa-chevron-right"></i> Sector Compliance Audit, finance, health care, energy, military/government contractors</li>
                            </ul>

                            <p className="my-3">Once the audits have been carried out, a corresponding report is issued detailing
                                the tests carried out and improvement solutions are proposed.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
export default ConsultingServices